import { useState } from "react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Transaction } from "@solana/web3.js";
import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

export const Sender: FC = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const { tx } = useParams();
  const [sig, setSig] = useState("");

  const onClick = useCallback(async () => {
    try {
      if (!publicKey) throw new WalletNotConnectedError();
      if (!tx) throw new Error("No tx provided");

      const transaction = Transaction.from(Buffer.from(tx, "base64"));

      const {
        context: { slot: minContextSlot },
        value: { blockhash, lastValidBlockHeight },
      } = await connection.getLatestBlockhashAndContext();

      console.log("minContextSlot", minContextSlot);

      transaction.recentBlockhash = blockhash;

      const toastLoading = toast.loading("Sending transaction...");

      try {
        const signature = await sendTransaction(transaction, connection);
        toast.success(`Transaction sent`);
        toast.dismiss(toastLoading);
        const toastWaiting = toast.loading("Waiting for confirmation...");
        await connection.confirmTransaction({
          blockhash,
          lastValidBlockHeight,
          signature,
        });
        toast.dismiss(toastWaiting);
        toast.success(`Transaction confirmed!`);
        setSig(signature);
        console.log("Signature: " + signature);
      } catch (error: any) {
        var errorMessage = error.toString() || "Transaction failed";
        toast.error(errorMessage);
        toast.dismiss(toastLoading);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey, sendTransaction, connection]);

  const solscanLink = "https://solscan.io/tx/" + sig + "?cluster=devnet";
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        marginBottom: 10,
      }}
    >
      <div style={{ marginBottom: 5, marginTop: 25 }}>
        <h3 style={{ marginBottom: 5 }}>Transaction</h3>
        <div>{tx?.substring(0, 10) + "..." + tx?.slice(-10)}</div>
      </div>

      {sig !== "" ? (
        <>
          <div
            style={{
              marginTop: 30,
              color: "green",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            Transaction Successful!
            <img
              alt={"tooltip"}
              src="./assets/check.png"
              style={{ maxHeight: 75, marginTop: 10 }}
            />
            <a
              href={solscanLink}
              target="blank"
              style={{ marginTop: 20, textDecoration: "none" }}
            >
              <button
                className="button"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                View Transaction
              </button>
            </a>
          </div>
        </>
      ) : publicKey ? (
        <button
          className="button"
          onClick={onClick}
          disabled={!publicKey}
          style={{ marginTop: 10 }}
        >
          Execute Transaction
        </button>
      ) : null}
    </div>
  );
};
