import * as web3 from "@solana/web3.js";
import { useState } from "react";
import "./App.css";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

function Creator() {
  const [tx, setTx] = useState("");
  const { publicKey } = useWallet();
  const { connection } = useConnection();

  console.log("tx", tx);

  const onClickCreateTransaction = async () => {
    // Add transfer instruction to transaction
    const transaction = new web3.Transaction().add(
      web3.SystemProgram.transfer({
        fromPubkey: publicKey!,
        toPubkey: new web3.PublicKey(
          "Be89d8MXBAtGxfV7iW7GAUQ3ecLNo48cT4K1dBKEwkqN"
        ),
        lamports: 10,
      })
    );
    transaction.feePayer = publicKey!;
    const latestBlockhash = await connection.getLatestBlockhash();
    console.log(connection.rpcEndpoint);
    console.log("latestBlockhash", latestBlockhash);
    transaction.recentBlockhash = latestBlockhash.blockhash;

    const tx = encodeURIComponent(
      transaction
        .serialize({ requireAllSignatures: false, verifySignatures: false })
        .toString("base64")
    );
    setTx(tx);
    window.location.href = "/" + tx;
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        marginTop: 30,
        marginBottom: 10,
        overflow: "hidden",
      }}
    >
      {publicKey && (
        <button onClick={onClickCreateTransaction} className="button">
          Create Transaction
        </button>
      )}
    </div>
  );
}

export default Creator;
