import React from "react";
import { Wallet } from "./Wallet";
import { Sender } from "./Sender";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="app">
      <div className="container">
        <h2
          style={{
            marginTop: 0,
            color: "#454f5b",
            fontFamily: "font-family: Public Sans,sans-serif",
          }}
        >
          Discord Sender
        </h2>
        <Wallet>
          <Sender />
        </Wallet>
        <Toaster />
      </div>
    </div>
  );
}

export default App;
