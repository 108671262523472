import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Keypair, LAMPORTS_PER_SOL } from "@solana/web3.js";
import React, { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { NFTChallenge } from "@ludex-labs/ludex-sdk-js";

export const Deal: FC = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const { challengeId, amount } = useParams();

  const onClick = useCallback(async () => {
    try {
      if (!publicKey) throw new WalletNotConnectedError();
      if (!challengeId) throw new Error("No challenge id provided");
      if (!amount) throw new Error("No amount provided");

      const txClient = new NFTChallenge.NftChallengeTXClient(
        connection,
        challengeId,
        Keypair.generate()
      );

      const user = publicKey.toString();
      const _amount = parseFloat(amount);

      const transaction = (
        await (
          await (
            await txClient.join(user)
          ).addSolOffering(user, _amount * LAMPORTS_PER_SOL)
        ).accept(user)
      ).tx;

      const {
        value: { blockhash, lastValidBlockHeight },
      } = await connection.getLatestBlockhashAndContext();
      transaction.recentBlockhash = blockhash;

      const toastLoading = toast.loading("Sending transaction...");

      try {
        const signature = await sendTransaction(transaction, connection);
        toast.success(`Transaction sent: ${signature}`);
        toast.loading("Waiting for confirmation...");
        await connection.confirmTransaction({
          blockhash,
          lastValidBlockHeight,
          signature,
        });
        toast.dismiss(toastLoading);
        toast.success(`Transaction confirmed: ${signature}`);
      } catch (error: any) {
        var errorMessage = error.toString() || "Transaction failed";
        toast.error(errorMessage);
        toast.dismiss(toastLoading);
      }
    } catch (error) {
      console.log(error);
    }
  }, [publicKey, sendTransaction, connection, amount, challengeId]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        marginBottom: 10,
      }}
    >
      <div style={{ marginBottom: 5, marginTop: 25 }}>
        <h3 style={{ marginBottom: 5 }}>Deal</h3>
      </div>

      {publicKey && (
        <button
          className="button"
          onClick={onClick}
          disabled={!publicKey}
          style={{ marginTop: 10 }}
        >
          Execute Transaction
        </button>
      )}
    </div>
  );
};
